<template>
  <div v-if="targetUUID" class="targetuuid">TARGET UUID: {{ targetUUID }} - <span @click="resetTarget">Click here to clear Target</span></div>
  <div class="p-5 flex flex-column">
    <div class="tab-container">
        <h2>INBOX: {{ inboxName }}</h2>
      <br />
      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/adminsecuremessaging'"/>
      <div v-if="mobile">
        <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/adminsecuremessaging'"/>
        <spacer-h/>
      </div>
      <router-view></router-view>
    </div>
    <ToastError />
  </div>
</template>

<script>
import SpacerH from '@/components/common/layout/SpacerH.vue'
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import TabViewHeaders from "@/components/nav/TabViewHeaders";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import JSONCrush from "jsoncrush";
import ToastError from "../components/common/ToastError.vue";

export default {
  name: "SecureMessaging",
  components: {
    ToastError,
    TabViewDropdown,
    TabViewHeaders,
    SpacerH
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const headers = computed(() => store.getters.getTabHeaders('adminsecuremessaging'))
    const mobile = ref(false)
    const screenSize = computed(() => store.getters.getTabView)

    const targetUUID = computed(() => store.getters.targetUUID)

    const inboxName = computed(() => {
        if(targetUUID.value) {
            switch(targetUUID.value) {
                case 'c_admin':
                    return 'Admin Team'
                case 'c_ims':
                    return 'IMS Team'
                case 'c_dfm':
                    return 'DFM Team'
                case 'c_enq':
                    return 'Enquiries'
                default:
                    if(targetUUID.value.startsWith('c_')){
                        // look for the title in the recipients.
                        const recipientSearch = store.getters.additionalRecipients;
                        const index = recipientSearch.findIndex(x => x.uuid === targetUUID.value);
                        if(index !== -1){
                            return recipientSearch[index].name
                        }
                    }
            }
        }
        return '';
    })

    const onTabHeadersChange = (payload) => {
      store.dispatch('setActiveHeader', {page: 'adminsecuremessaging', headers: payload})
    }

    const isRootPage = computed(() => {
      return route.path === '/adminsecuremessaging'
    })

    onMounted(() => {

      if (isRootPage.value === true) {
        store.dispatch('setMailboxType', 'inbox')
        router.push('/adminsecuremessaging/inbox')
      }
      mobile.value = screenSize.value === 'mobile'

      if(route.query.templatemessage){

          const templateMessage = JSON.parse(JSONCrush.uncrush(decodeURIComponent(route.query.templatemessage)));
          if(templateMessage.category && templateMessage.messagebody && templateMessage.recipient && templateMessage.subject) {
              // create the new message.
              store.dispatch('globalMessageTrigger', {
                  subject: templateMessage.subject,
                  category: templateMessage.category,
                  messagebody: templateMessage.messagebody,
                  recipient: templateMessage.recipient,
                  lockDraft: templateMessage.lockDraft
              })
              router.push('/adminsecuremessaging/message')
          }
      }

    })

    watch(screenSize, (val) => {
      mobile.value = val === 'mobile'
    })


    if(!targetUUID.value){
        if(route.query.targetuuid) {
            store.dispatch('setTargetUUID',route.query.targetuuid);
        }else{
            //redirect away
            router.push('/error')
        }
    }

    const resetTarget = () => {
        store.dispatch('setTargetUUID',false);
        window.location = window.location.href.split("?")[0];
    }

    return {
      headers,
      mobile,
      onTabHeadersChange,
      targetUUID,
      resetTarget,
      inboxName
    }
  }
}
</script>

<style scoped>
.targetuuid {
    position: sticky;
    top: 0px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    z-index: 50000;
    background: var(--clarity-dark-grey);
    color: var(--clarity-pure-white);
}
</style>
